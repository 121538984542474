<template>
  <v-container fill-height fluid class="justify-center">

      <!-- Sanckbar d'erreur -->
      <v-snackbar v-model="snackErreur" color="red">
        {{snackMessage}}
        <v-btn text color="black" @click.native="snackErreur = false">Close</v-btn>
      </v-snackbar>

      <v-card class="pa-3" style="max-width: 650px" center>
        <v-card-title primary-title>
          Demande de mot réinitialisation de mot de passe
        </v-card-title>
        <v-card-text>
          <v-text-field label="Adresse de courriel" autofocus v-model="courriel"></v-text-field>
          <div v-if="demandeEnvoyee" style="color: #008000" >Demande de réinitialisation envoyée, consultez votre messagerie.</div>
          <div v-if="demandeErreur" style="color: red" >{{ erreur }}</div>
        </v-card-text>
        <v-card-actions>
          <v-btn color="success" :loading="loading" @click="boutonEnvoyerClick">Envoyer la demande</v-btn>
        </v-card-actions>
      </v-card>

    </v-container>
</template>

<script>
import axios from 'axios';

export default {

  data() {
    return {
      loading: false,
      snackErreur: false,
      snackMessage: "",
      courriel: "",
      demandeEnvoyee: false,
      demandeErreur: false,
      erreur: ""
    }
  },

  watch: {
    $route(to) {
      if (to.url.startsWith('/request-password-reset/')) {
        this.demandeEnvoyee = false
        this.courriel = ""
      }
    }
  },

  methods: {  
    boutonEnvoyerClick() {
      if (this.courriel == "") {
        return
      }
      if (this.courriel.indexOf("@") < 0) {
        return
      }
      if (this.courriel.indexOf(".") < 0) {
        return
      }
      this.demandeEnvoyee = false
      this.demandeErreur = false
      this.erreur = ""
      this.loading = true
      if (this.courriel != "") {
        const body = {
          "email": this.courriel
        }
        axios.post("/api/request-password-reset", body)
          .then(function() {
            this.loading = false
            this.demandeEnvoyee = true;
          }.bind(this))
          .catch(function(error) {
            this.loading = false
            if ((error.response != undefined) && (error.response.status == 401)) {
              // Erreur "accès refusé", redirection vers la page de connexion
              this.erreur = "Demande refusée, adresse de courriel inconnue"
              this.demandeErreur = true
              //this.$router.push('/login?to=/containers')
            }
            else {
              this.erreur = error.message + " : " + error.response.statusText
              this.demandeErreur = true
            }
          }.bind(this));
      }
    }
  }

}
</script>

<style>

</style>